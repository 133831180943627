import React from 'react';
import Header from "components/header";
import 'styles/containers/contactUsScreen.scss'
import ContactCard from 'components/contactCards';
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import Footer from 'components/footer';

interface props{

}

interface state{

}

class ContactScreen extends React.Component<props,state> {

    render(){
        return(
            <div>
                <Header/>
                <div className={'main-contact'}>
                    <h1>CONTACT US</h1>
                    <p>Didn't find what you are looking for? Drop a mail or call us. We are happy to assist you.</p>
                    <div className={'contact-details'}>
                        <iframe title="gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d67354.38710127078!2d74.69797638869208!3d13.460895795542427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTPCsDI4JzEzLjYiTiA3NMKwNDMnMjYuNSJF!5e0!3m2!1sen!2sin!4v1617470295607!5m2!1sen!2sin" className={'map'} loading="lazy"></iframe>
                        <div className={'column'}>
                            <ContactCard icon={faMapMarkerAlt}>
                                <b>Registered Office</b>
                                <p>#2-127,Thirthabailu</p>
                                <p>Pandeshwara Sastana 576 226</p>
                                <p>Udupi Karnataka INDIA</p>
                            </ContactCard>
                            <ContactCard icon={faEnvelope}>
                                <a href="mailto: connect@apsaram.com">connect@apsaram.com</a>
                            </ContactCard>
                            <ContactCard icon={faPhoneAlt}>
                                <a href="tel:+91 9986987695">+91 9986987695</a>
                            </ContactCard>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default ContactScreen;