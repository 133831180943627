import React, { useEffect, useState } from 'react';
import 'styles/components/counter/counterCard.scss'

interface props {
    count: number
    desc: String
    type: String
}

const CounterCard = (props: props) => {

    const [numero,setNumber ] = useState(0)

  

    useEffect(()=>{
        const incrementCounter = (prevNumber: any) => {
            let stepSize = props.count > 100 ? 10 : 1
            if(prevNumber < props.count - 10 ){
                return prevNumber+stepSize
            }
            if(prevNumber < props.count - 1){
                return prevNumber + 1;
            }
            if(prevNumber < props.count){
                return prevNumber + .1
            }
            return prevNumber;
        }

        const interval = setInterval(()=>{
            setNumber(incrementCounter);
        },50);
        return () =>{
            clearInterval(interval)
        }
    },[props.count])



    return (
        <div className={'counter-card'}>
            <b className={'count'}>{props.type==="integer"?(Math.floor(numero)+" +"):numero.toFixed(1)}</b>
            <b>{props.desc}</b>
        </div>
    );
}

export default CounterCard;
