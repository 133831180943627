 import {NavLink, withRouter} from 'react-router-dom'
 import logo from 'assets/images/logo.png'
 import 'styles/components/header.scss'
import React from 'react';
import HamburgerMenu from './HamBurgerMenu';


 const Header = () => (
     <div className={'header'} >

        <NavLink to={'/home'}><img src={logo} alt="Apsaram Logo"></img></NavLink>
         
         <div className={'hamburger'}>
                <HamburgerMenu/>
        </div>
         <div className={'menu'}>
            <ul>
                <li><NavLink activeStyle={{color: '#70A040'}} to={'/home'}>HOME</NavLink></li>
                <li><NavLink activeStyle={{color: '#70A040'}} to={'/services'}>SERVICES</NavLink></li>
                <li><NavLink activeStyle={{color: '#70A040'}} to={'/solutions'}>SOLUTIONS</NavLink></li>
                <li><NavLink activeStyle={{color: '#70A040'}} to={'/gallery'}>GALLERY</NavLink></li>
                <li><NavLink activeStyle={{color: '#70A040'}} to={'/contact'}>CONTACT</NavLink></li>
                <li><NavLink activeStyle={{color: '#70A040'}} to={'/about'}>ABOUT</NavLink></li>
            </ul>
         </div>
     </div>
 );

 export default withRouter(Header);