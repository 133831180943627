import DraggableButton from "components/draggableButton";
import AboutScreen from "containers/aboutScreen";
import ContactScreen from "containers/contactScreen";
import GalleryScreen from "containers/galleryScreen";
import HelpScreen from "containers/helpScreen";
import HomeScreen from "containers/homeScreen";
import ServiceScreen from "containers/serviceScreen";
import SolutionScreen from "containers/solutionScreen";
import {BrowserRouter as Router,Switch,Route,Redirect, BrowserRouter} from "react-router-dom";

const Routes = () => (
    <Router>
        <BrowserRouter>
            <DraggableButton />
            <Switch>
                <Route exact path='/'>
                    <Redirect to="/home"></Redirect>
                </Route>
                <Route exact path='/home' component={HomeScreen}></Route>
                <Route exact path='/services' component={ServiceScreen}></Route>
                <Route exact path='/solutions' component={SolutionScreen}></Route>
                <Route exact path='/gallery' component={GalleryScreen}></Route>
                <Route exact path='/contact' component={ContactScreen}></Route>
                <Route exact path='/about' component={AboutScreen}></Route>
                <Route exact path='/surya-help' component={HelpScreen}></Route>
            </Switch>
        </BrowserRouter>
    </Router>
);

export default Routes;