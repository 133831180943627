
import React, { useEffect, useState } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import { TransitionGroup,CSSTransition} from 'react-transition-group';
import { NavLink } from 'react-router-dom';
import 'styles/components/hamBurgerMenu.scss';

const HamburgerMenu = ()=>{

    const [menuState, setMenuState] = useState(false);

    const nodeRef = React.useRef();

    useEffect(()=>{
        if(menuState){
            document.body.classList.add('no-scroll');
        }else{
            document.body.classList.remove('no-scroll');
        }
    },[menuState])

    const toggleMenu = () => {
        setMenuState(!menuState)
    }

    return (
        <div>
            <button className={'menu-button'} onClick= {toggleMenu}>
                { !menuState && <FontAwesomeIcon icon={faBars}/> }
            </button> 
            <TransitionGroup component={null}>
            {menuState &&(
                <CSSTransition nodeRef={nodeRef} classNames="menu-drawer" timeout={300}>
                        <div className={'menu-body'}>
                            <div className={'drawer'}>
                                <ul className={'menu-list'}>
                                    <li><NavLink activeStyle={{color: '#70A040'}} to={'/home'}>HOME</NavLink></li>
                                    <li><NavLink activeStyle={{color: '#70A040'}} to={'/services'}>SERVICES</NavLink></li>
                                    <li><NavLink activeStyle={{color: '#70A040'}} to={'/solutions'}>SOLUTIONS</NavLink></li>
                                    <li><NavLink activeStyle={{color: '#70A040'}} to={'/gallery'}>GALLERY</NavLink></li>
                                    <li><NavLink activeStyle={{color: '#70A040'}} to={'/contact'}>CONTACT</NavLink></li>
                                    <li><NavLink activeStyle={{color: '#70A040'}} to={'/about'}>ABOUT</NavLink></li>
                                </ul>
                            </div>
                            <button className={'menu-button'} onClick= {toggleMenu}>
                                {  <FontAwesomeIcon icon={faTimes}/>}
                            </button> 
                        </div>
                </CSSTransition>)
            }   
            </TransitionGroup>
        </div>
    );
}

export default HamburgerMenu;