import React from 'react';
import Header from "components/header";
import 'styles/containers/aboutUsScreen.scss'
import Tile from 'components/tile' 
import {WE_HEADING,WE_TEXT,AIM_HEADING,AIM_TEXT} from 'constants/aboutscreen'
import goGreen from 'assets/images/go_green.png'
import we from 'assets/images/group.png'
import Footer from 'components/footer';

interface props {

}

interface state {

}

class AboutScreen extends React.Component<props,state> {
    render(){
        return(
            <div>
                <Header/>
                <div className={"main-about"}>
                    <h1>ABOUT US</h1>
                    <p>Simplifying the lives of mankind following sustainable practices, using the principles of Engineering and Technology is the seed for Apsaram Technologies Pvt Ltd. A brainchild of a young and dynamic team graduated from National Institute of Technology Karnataka, Surathkal, Apsaram was founded in 2017 at Udupi. At Apsaram, we focus on providing best class solutions to engineering problems in the field of Renewable Energy and Electrical Engineering with the belief in simplicity to achieve the complex. 
In a short span of just 4 years, we have gained the promise of our customers for providing the best quality products and services. Further, we strive at being one of the top global leaders in the market of Power and Renewable Engineering Technologies, promising our customers the best class solutions, and promising this world a sustainable Eco System.</p>
                    <div className={'staggered-tile'}>
                        <Tile image={we} heading={WE_HEADING} text={WE_TEXT} tileFloat={'left'} styled={true}></Tile>
                        <Tile image={goGreen} heading={AIM_HEADING} text={AIM_TEXT} tileFloat={'right'}styled={true}></Tile>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default AboutScreen;