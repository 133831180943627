import React from 'react';
import Header from 'components/header';
import PolaroidImage from 'components/polaroidImage';
import 'styles/containers/galleryScreen.scss';
import Footer from 'components/footer';
import imgOne from "assets/images/galleryImages/gal_img1.jpg"
import imgTwo from "assets/images/galleryImages/gal_img2.jpg"
import imgThree from "assets/images/galleryImages/gal_img3.jpg"
import imgFour from "assets/images/galleryImages/gal_img4.jpg"
import imgFive from "assets/images/galleryImages/gal_img5.jpg"
import imgSix from "assets/images/galleryImages/gal_img6.jpg"



interface props{

}

interface state{

}

const images = [imgOne,imgTwo,imgThree,imgFour,imgFive,imgSix]

class GalleryScreen extends React.Component<props,state> {

    render(){
        return(
            <div>
                <Header />
                <div className={'gallery-main'}>
                    <h1>GALLERY</h1>
                    <div className={'gallery-grid'}>
                        {
                            images.map(img => {
                                return <PolaroidImage img={img}/>
                            })
                        }
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default GalleryScreen;