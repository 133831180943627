import React from 'react'
import styled from 'styled-components';


interface props {
    content?: any
}

const Images = styled.div<props>`
    height: 100%;
    width: 100%;
    background-image: url('${props => props.content}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const Slide = (props :props) => {

    return(
        <Images content={props.content}></Images>
    )
    
}

export default Slide;