import React, { FunctionComponent } from 'react';
import {CSSTransition} from 'react-transition-group';
import 'styles/components/animatedEntryClass.scss'

interface props{
    inCond:boolean;
}

const AnimatedEntryClass: FunctionComponent<props> = ({inCond,children}) =>{
    const nodeRef = React.useRef(null)
    return(
        <CSSTransition nodeRef={nodeRef} classNames={"animated"} timeout={5000} in={inCond}>
            {
                <div ref={nodeRef}>
                    {children}
                </div>
            }
        </CSSTransition>
    )
}

export {AnimatedEntryClass}