import React, { useEffect, useRef, useState } from 'react'
import 'styles/components/slider/slider.scss'
import ArrowButtons from './arrowButtons';
import Dots from './pageIndicators';
import Slide from './slide';
import SlideContiner from './sliderContent'

interface SlideChangeRef {
  changeSlide: () => void
}

interface TransitionRef {
  transit: () => void
}

interface ResizeRef {
  resize: () => void
}

const Slider = (props: any) => {

  const { slides } = props;

  const firstSlide = slides[0]
  const middleSlide = slides[1]
  const lastSlide = slides[slides.length - 1]

  const getWidth = () => window.innerWidth;


  const [state, setState] = useState({
    activeIndex: 0,
    translate: getWidth(),
    transition: 0.45,
    _slides : [lastSlide,firstSlide,middleSlide]
  })

  const { translate, transition, activeIndex,_slides } = state


  const nextSlide = () => {
      return setState({
        ...state,
        translate: translate + getWidth(),
        activeIndex: (activeIndex === slides.length - 1 )? 0 : activeIndex + 1
      })
  }

  const prevSlide = () => {
      return setState({
        ...state,
        translate: 0,
        activeIndex: activeIndex === 0 ? slides.length - 1 : activeIndex - 1
      })

  }

  const smoothTransition = () => {
    let _slides = []


    if (activeIndex === slides.length - 1)
      _slides = [slides[slides.length - 2], lastSlide, firstSlide]
    else if (activeIndex === 0) _slides = [lastSlide, firstSlide, slides[activeIndex+1]]
    else _slides = slides.slice(activeIndex - 1, activeIndex + 2)

    setState({
      ...state,
      _slides,
      transition: 0,
      translate: getWidth()
    })
  }


  const handleResize = () => {
    setState({ ...state, translate: getWidth(), transition: 0 })
  }

  const autoPlayRef = useRef<SlideChangeRef>({ changeSlide: nextSlide })
  const transitionRef = useRef<TransitionRef>({ transit: smoothTransition })
  const resizeRef = useRef<ResizeRef>({resize: handleResize})

  useEffect(() => {
    autoPlayRef.current.changeSlide = nextSlide
    transitionRef.current.transit = smoothTransition
    resizeRef.current.resize = handleResize
  })

  useEffect(() => {
    const play = () => {
      autoPlayRef.current?.changeSlide();
    }

    const smooth = () => {
      transitionRef.current?.transit();
    }

    const adjustSize = () =>{
      resizeRef.current.resize();
    }

    const interval = setInterval(play, props.autoPlay * 1000)
    window.addEventListener('transitionend', smooth)
    window.addEventListener('resize',adjustSize)


    return () => {
      clearInterval(interval)
      window.removeEventListener('transitionend', smooth)
      window.removeEventListener('resize',adjustSize)
    }
  },[props])

  useEffect(() => { 
    if (transition === 0) setState({ ...state, transition: 0.45 }) 
  }, [transition,state])


  return (
    <div className={'slider'}>
      <SlideContiner transition={transition} translate={translate} width={getWidth() * _slides.length}>
        {_slides.map((_slide: any, i: any) => (
          <Slide key={_slide + i} content={_slide} />
        ))}
      </SlideContiner>
      <ArrowButtons direction="left" handleClick={prevSlide} />
      <ArrowButtons direction="right" handleClick={nextSlide} />
      <Dots slides={props.slides} activeIndex={activeIndex}></Dots>
      <span className={"tagline"}>For a <b>GREENER</b> tomorrow...</span>
    </div>
  )
}

export default Slider;