import React from 'react'
import styled from 'styled-components'
import 'styles/components/slider/pageIndicators.scss'

interface props {
    slides: any
    activeIndex: number
} 

const Dot = styled.span<{active: boolean}>`
      margin-right: 5px;
      cursor: pointer;
      height:1px;
      width:40px;
      background: ${props => props.active ? 'yellow' : 'white'};
    `

const Dots = (props: props) =>{
    return(
        <div className={'indicators'}>
        {props.slides.map((slide: any, i: number) => (
            <Dot key={slide} active={props.activeIndex === i} />
        ))}
        </div>
    )
}

export default Dots
