import styled from 'styled-components';

interface StyleProps {
    translate?: any
    transition?: number
    width?: number
}

const SlideContiner = styled.div<StyleProps>`
    transform: translateX(-${props => props.translate}px);
    transition: transform ease-out ${props => props.transition}s;
    height: 100%;
    width: ${props => props.width}px;
    display: flex;
`;

export default SlideContiner
