import React from 'react';
import Header from "components/header";
import Slider from 'components/slider/slider';
import 'styles/containers/homeScreen.scss';
import slide_one from 'assets/images/slides/slide_one.png'
import roof_top from 'assets/images/slides/roof_top_panel.png'
import entreprisenew from 'assets/images/slides/entreprisenew.png'
import Footer from 'components/footer';
import quotes from 'assets/images/quote.png'
import panal from 'assets/images/panal.png'
import info from 'assets/images/info.png'
import PlainCard from 'components/plainCard';
import { FACT_HEADER, FACT_TEXT, QUOTE_HEADER, QUOTE_TEXT, QUOTE_TEXT_BOLD, WE_HEADER } from 'constants/homscreen';
import { WE_TEXT } from 'constants/aboutscreen';
import noCost from "assets/images/no_cost.png"
import investment from "assets/images/investment.png"
import storage from "assets/images/storage.png"
import low_maintainance from "assets/images/low_maintainance.png"
import taxBenifit from "assets/images/tax.png"
import ecoPride from "assets/images/eco_pride.png"
import Counter from 'components/counter/counter';


interface props {

}

interface state {
    
}

const images = [ slide_one,roof_top,entreprisenew]


const counterData = [
    {count: 20,desc: 'Happy Customer',type:"integer"},
    {count: 3.3,desc: 'MWs of green energy produced'},
    {count: 864,desc: 'Number of trees saved', type:"integer"},
    {count: 3.1,desc: 'Tons of Carbon footprint cut-off'}
]

class HomeScreen extends React.Component<props,state> {
    
    render(){
        return(
            <div className={"main"}>
                <Header/>
                <Slider slides={images} autoPlay={2}/>
                <div className={"welcome"}>
                    <PlainCard img={quotes} header={QUOTE_HEADER} text={QUOTE_TEXT} bold={QUOTE_TEXT_BOLD} imagePos={"left"}/>
                    <PlainCard img={info} header={FACT_HEADER} text={FACT_TEXT} imagePos={"right"}/>
                    <PlainCard img={panal} header={WE_HEADER} text={WE_TEXT} imagePos={"left"}/>
                    <Counter data={counterData } heading={"OUR IMPACT..."}/>

                    <div className={'benefits'}>
                        <h1>KEY BENEFITS...</h1>
                        <ul>
                                <li><img src={noCost} alt={''}/>You produce electricity for <b>YOURSELF</b> effectively reducing your <b>ELECTRYCITY BILL TO ZERO.</b></li>
                                <li><img src={investment} alt={''}/>Surplus electricity can be given back to grid which will result in <b>HIGH RETURN OF INVESTMENTS </b>over longer period of time.</li>
                                <li><img src={storage} alt={''}/>You will store energy at absolutely <b>ZERO</b> cost.</li>
                                <li><img src={low_maintainance} alt={''}/>Time to time maintainance of setup results in <b>LOW MAINTAINENCE COST</b> for you.</li>
                                <li><img src={taxBenifit} alt={''}/>Don't get surprised if <b>YOU GET TAX BENIFITS</b> also.</li>
                                <li><img src={ecoPride} alt={''}/>Pride of being <b>ECO-FRIENDLY </b>citizen.</li>
                        </ul>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default HomeScreen;