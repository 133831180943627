import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { useHistory } from 'react-router-dom';
import 'styles/components/draggableButton.scss'
import sun from 'assets/images/sun.gif'

const DraggableButton = () => {
    
    var [xoff, setXoff] =useState(0)
    var [yoff,setYoff] = useState(0)
    const history = useHistory()

    const onStart = () => {
        setXoff(++(xoff));
    };
    
    const onStop = () => {
        setYoff(--(yoff));
    };

    const navigateToHelp = (event: any) =>{
        history.push('/surya-help');
    }

    const nodeRef = React.useRef(null);
    const dragHandlers = {onStart: onStart, onStop: onStop,bounds:'parent'};

    return(
       
        <Draggable {...dragHandlers} nodeRef={nodeRef}>
            <div ref={nodeRef} className={"draggable-button"}>
                <div className={"inner-circle"} onClick={navigateToHelp} onTouchStart={navigateToHelp}>
                        <img src={sun} alt={''} />
                </div>
                <div className={"button-text"}>
                    <h5>Hi! I am SURYA</h5>
                    <span>Click in the circle for me to help You</span>
                </div>
            </div>
        </Draggable>
    )

}

export default DraggableButton;