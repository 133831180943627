import React, { useState,useEffect } from 'react'
import styled from 'styled-components'
import 'styles/components/tile.scss'
import { AnimatedEntryClass } from 'components/entryContainer'


interface props{
    image?: any
    heading?: string
    text?: string
    tileFloat?: string
    styled ?: boolean 
    width ?: number
}

interface StyleProps{
    float?: string
    styled? : boolean
    width?: number 
}


const StyledTile = styled.div<StyleProps>`
    display: inline-flex;
    flex-wrap: wrap;
    width: ${props => props.width?props.width:'100'}%;
    margin-block: ${props => props.styled ? 5 : 3 }%;
    float: ${props => props.float?props.float:'left'};
`

const Tile = (props: props) =>{

    const [mount,setMount] = useState(false)

    useEffect(()=>{
        setMount(true)
    },[mount]); 

    return(
      <AnimatedEntryClass inCond={mount}>
          <StyledTile float={props.tileFloat} styled={props.styled} width={props.width}>
                <div className={props.styled ? 'image' : 'regular' }>
                    <img src={props.image} alt={'tile-img'}></img>
                </div>
                <div className={"content"}>
                    <h2>{props.heading}</h2>
                    <p>{props.text}</p>
                </div>
          </StyledTile>
    </AnimatedEntryClass>        
    )
}

export default Tile;