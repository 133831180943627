import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent } from 'react'
import 'styles/components/contactCard.scss'

interface props {
    icon: IconProp
}


const ContactCard: FunctionComponent<props> = ({icon,children}) =>{
    return(
        <div className={'contact-card'}>
            <div className={'icon'}>
                <FontAwesomeIcon icon={icon} color={'#70A040'}></FontAwesomeIcon>
            </div>
            <div className="contact-text">
                {children}
            </div>

        </div>
    );
}

export default ContactCard;