import React from 'react';
import Header from "components/header";
import Card from 'components/cards';
import { DOMESTIC_SOLUTION, DOMESTIC_TEXT, ENTERPRISE_SOLUTIONS, ENTERPRISE_TEXT, INSTITUTIONAL_SOLUTION, INSTITUTIONAL_TEXT } from 'constants/solutionscreen';
import domestic from 'assets/images/domestic.png'
import institutional from 'assets/images/institutional.png'
import enterprise from 'assets/images/enterprise.png'
import 'styles/containers/solutionScreen.scss'
import Footer from 'components/footer';

interface props{

}

interface state{

}

class SolutionScreen extends React.Component<props,state> {

    render(){
        return(
            <div>
                <Header />
                <div className={'main-solution'}>
                    <h1>SOLUTIONS</h1>
                    <div className={'card-row'}>
                        <Card heading={DOMESTIC_SOLUTION} text={DOMESTIC_TEXT} width={325} height={400} image={domestic} />
                        <Card heading={INSTITUTIONAL_SOLUTION} text={INSTITUTIONAL_TEXT} width={325} height={400} image={institutional} />
                        <Card heading={ENTERPRISE_SOLUTIONS} text={ENTERPRISE_TEXT} width={325} height={400} image={enterprise} />
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default SolutionScreen;