import React, { useEffect, useState } from 'react';
import 'styles/components/plainCard.scss'
import { AnimatedEntryClass } from './entryContainer';

interface props {
    img? : any
    header? :string
    text? : string
    imagePos?: string
    bold?:string
}


const PlainCard = (props: props) => {

    const {innerWidth} = window

    const [pos,setPos] = useState(props.imagePos)
    const [mount,setMount] = useState(false)

    const breakPoint = 1098;

    useEffect(()=>{
        if(innerWidth < breakPoint){
            setPos('left')
        }
    },[innerWidth]);

    useEffect(()=>{
        setMount(true)
    },[mount]); 


    return (
        <div className={'content-box'}>
            <div className={'contents'}>
                <AnimatedEntryClass inCond = {mount}>
                    {(pos === 'left' ) && <img src={props.img} alt={''}/> }
                </AnimatedEntryClass>
                <div className={'content-text'}>
                    <h1>{props.header}</h1>
                    <p>{props.bold && <b>{props.bold}<br/></b>}{props.text}
                    </p>
                </div>
                {pos === 'right' && <img src={props.img} alt={''} /> }
            </div>
        </div>
    );
}

export default PlainCard;


