import React from 'react'
import styled from 'styled-components'
import 'styles/components/card.scss'

interface props{
    image?: any
    heading?: string
    text?: string
    width: number
    height: number
}

interface styleProps{
    width : number
    height : number 
}

const StyledCard = styled.div<styleProps>`
    width: ${props => props.width}px;
    min-width: 200px;
    height: ${props => props.height}px;
    min-height: 300px;
    border: 1px solid #d1d1d1;
    margin-block: 10px;    
    &:hover{
        box-shadow: 0px 0px  rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
`

const Card = (props: props) =>{
    return (
        <StyledCard height={props.height} width={props.width}>
                <div className={'card-image'}>
                    <img src={props.image} alt="card-img"></img>
               </div>
                <div className={'card-content'}>
                    <h2>{props.heading}</h2>
                    <p>{props.text}</p>
                </div>
        </StyledCard>
           
    )
}

export default Card;