import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

interface props {
    direction: string
    handleClick: any
}

const ArrowButton = styled.div<{direction: string}>`
    display: flex;
    position: absolute;
    top: 50%;
    ${props => props.direction === 'right' ? `right: 25px` : `left: 25px`};
    height: 50px;
    width: 50px;
    justify-content: center;
    background: white;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    transition: transform ease-in 0.1s;
    &:hover {
        transform: scale(1.1);
    }
`


const ArrowButtons = (props: props) =>{

    return (
        <div onClick={props.handleClick}>
            <ArrowButton direction={props.direction}>
                {props.direction === 'right' ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronLeft} /> }
            </ArrowButton>
        </div>
    )
}

export default ArrowButtons