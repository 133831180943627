import React from 'react'
import 'styles/components/polaroidImage.scss'
interface props {
    img: any 
}

const PolaroidImage = (props: props) => {

    return (
        <div className={'polaroid'}>
            <img className="photo" src={props.img} alt={'gallery-img'}></img>
        </div>
    );
}

export default PolaroidImage;