import React from 'react';
import Header from "components/header";
import 'styles/containers/serviceScreen.scss'
import Tile from 'components/tile';
import statuatory from 'assets/images/paper.png'
import solar from 'assets/images/solar.png'
import service from 'assets/images/service.png'
import { INSTALLATION_HEADING, INSTALLATION_TEXT, PAPERWORK_HEADING, PAPERWORK_TEXT, SERVICE_HEADING, SERVICE_TEXT } from 'constants/servicecreen';
import Footer from 'components/footer';

interface props{

}

interface state{

}

class ServiceScreen extends React.Component<props,state> {

    render(){
        return(
            <div>
                <Header/>
                <div className={'main-service'}>
                    <h1>SERVICES</h1>
                    <p>At Apsaram we believe that the service we offer is the experience we deliver with a promise. To work for such services is the greatest creed. We also believe that building such a relationship with customers is not by mere accident but by the design of our services</p>
                    <div className={'service-list'}>
                        <Tile heading={PAPERWORK_HEADING} text={PAPERWORK_TEXT} image={statuatory} width={100}></Tile>
                        <Tile heading={INSTALLATION_HEADING} text={INSTALLATION_TEXT} image={solar} width={100}></Tile>
                        <Tile heading={SERVICE_HEADING} text={SERVICE_TEXT} image={service} width={100}></Tile>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default ServiceScreen;