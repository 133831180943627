import Footer from 'components/footer';
import Header from 'components/header'
import React from 'react'
import emailjs from 'emailjs-com';
import 'styles/containers/helpScreen.scss'
import { Dialog, DialogContent, DialogContentText ,DialogTitle,DialogActions, Button} from '@mui/material';
import { SUBMIT_ERROR_TEXT, SUBMIT_SUCCESS_TEXT } from 'constants/helpscreen';

interface props{

}
interface state {
    name: string
    contactNumber: string
    email: string
    message : string
    validForm: boolean
    open: boolean
    dialogueSuccess: boolean
    dialogueText: string
}

class HelpScreen extends React.Component<props,state> {

    readonly EMAILJS_SERVICE_ID  = process.env.REACT_APP_EMAILJS_SERVICE_ID || '';
    readonly EMAILJS_TEMPLATE_ID =  process.env.REACT_APP_EMAILJS_TEMPLATE_ID || ''
    readonly EMAILJS_USER_ID  = process.env.REACT_APP_EMAILJS_USER_ID || ''

    constructor(props: props){
        super(props)
        this.state = {
            name: '',
            contactNumber: '',
            email: '',
            message: '',
            validForm:false,
            open: false,
            dialogueText: "",
            dialogueSuccess: false
        }
    }

    handleChange = (e: any) =>{
        e.preventDefault();
        this.setState({...this.state,[e.target.name]: e.target.value })
        if(e.target.name === "message" || e.target.name === "contactNumber"){
            this.isFormValid(e.target.value);
        }
    }

    isFormValid = ( val:string) =>{
        if( (this.state.contactNumber && val) || (this.state.message && val)) this.setState({validForm: true });
        else this.setState({validForm: false });
    }

    handleSubmit = (e:any) => {
        e.preventDefault();
        emailjs.sendForm(this.EMAILJS_SERVICE_ID, this.EMAILJS_TEMPLATE_ID ,e.target , this.EMAILJS_USER_ID)
          .then((result) => {
              this.setState({open: true, dialogueText: SUBMIT_SUCCESS_TEXT, dialogueSuccess: true})
          }, (error) => {
              this.setState({open: true, dialogueText: SUBMIT_ERROR_TEXT, dialogueSuccess: false})
          });
    }

    handlePopupClose = () =>{
        this.setState({
            name: '',
            contactNumber: '',
            email: '',
            message: '',
            validForm:false,
            open: false,
        });

    }

    render(){
        return(
            <div>
                <Header/>
                <div className={"main-help"}>
                    <h1>LET ME HELP YOU</h1>
                    <form className={"help-form"} onSubmit={this.handleSubmit} >
                        <div className={"form-group"}>
                            <label>Complete Name</label>
                            <input type="text" name={'name'} value={this.state.name} required onChange={this.handleChange}/>
                        </div>
                        <div className={"form-group"}>
                            <label className="required">Contact number </label>
                            <input type="text" name={'contactNumber'} value={this.state.contactNumber} onChange={this.handleChange} required/>
                        </div>
                        <div className={"form-group"}>
                            <label>Email ID</label>
                            <input type="text" name={'email'} value={this.state.email} onChange={this.handleChange}/>
                        </div>
                        <div className={"form-group"}>
                            <label className="required" >Enter Your query here</label>
                            <textarea name={'message'} value={this.state.message} onChange={this.handleChange} required ></textarea>
                        </div>
                        <input type="submit" value="Submit" disabled={!this.state.validForm} />

                    </form>
                </div> 
                <Footer/>
                <Dialog open={this.state.open}>
                    <DialogTitle>
                            {this.state.dialogueSuccess ? <span className="success">SUCCESS</span> :<span className="error">ERROR</span> }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {this.state.dialogueText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handlePopupClose}>OK</Button>
                    </DialogActions>
                </Dialog>
            </div>
            
        )
    } 
}

export default HelpScreen;
