import React from 'react'
import 'styles/components/footer.scss'

const Footer = () =>{
    return (
        
            <div className={'footer'}>
                © 2021 Copyrights Apsaram Technologies Private Limited. All rights reserved.    
            </div>
    )
}

export default Footer;