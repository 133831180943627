import React from 'react'
import CounterCard from './counterCard'
import 'styles/components/counter/counter.scss'

interface props{
    data:{count: number,desc: string, type?:string}[]
    heading: string
}

const Counter = (props: props) => {
    
    return (
        <div className={"counter"}>
            <h1>{props.heading}</h1>
            <div className={"counter-content"}>
                {
                    props.data.map((item,index) => {
                        return <CounterCard key={index} count={item.count} desc={item.desc} type={item.type || ''}/>
                    })
                }
            </div>
        </div>
    );

}

export default Counter